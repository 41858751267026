<template>
    <v-dialog width="500" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="capres" v-bind="attrs" v-on="on" icon>
                <v-icon large>mdi-information-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-text>
                <p>Artículo 32, literal D.</p>
                <br />
                <b>
                    <ol>
                        <li>
                            Si es propietario individual y es mujer deberá
                            seleccionar el género femenino. si es hombre
                            deberá seleccionar el género masculino.
                        </li>
                        <li>
                            Si los propietarios son socios o accionistas y la
                            mayoría son mujeres, el género a seleccionar será
                            femenino, pero si la mayoría son hombres debe
                            seleccionar el género masculino.
                        </li>
                        <li>
                            De existir el criterio anterior, se determinará
                            utilizando el género del Representante Legal.
                        </li>
                    </ol>
                </b>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalGeneroComponent",
    data: () => ({
        dialog: false,
    }),
};
</script>
