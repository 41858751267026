<template>
    <v-dialog width="800" v-model="dialog">
        <template v-slot:activator="{ on, attrs }">
            <v-btn color="capres" v-bind="attrs" v-on="on" icon>
                <v-icon large>mdi-information-outline</v-icon>
            </v-btn>
        </template>
        <v-card>
            <v-card-title>
                Indicadores para clasificación empresarial.
            </v-card-title>
            <v-card-text>
                <br />
                <v-data-table
                    :headers="headers"
                    :items="items"
                    :items-per-page="5"
                    hide-default-footer
                    class="elevation-0"
                ></v-data-table>
            </v-card-text>
        </v-card>
    </v-dialog>
</template>

<script>
export default {
    name: "ModalClasificacionComponent",
    data: () => ({
        dialog: false,
        headers: [
            {
                text: "Clasificación",
                align: "center",
                sortable: false,
                value: "clasificacion",
            },
            {
                text: "Laboral (Trabajadores permanentes remunerados)",
                align: "center",
                sortable: false,
                value: "laboral",
            },
            {
                text: "Ventas brutas anuales (Salarios mínimos urbanos sector comercio)",
                align: "center",
                sortable: false,
                value: "ventas",
            },
            {
                text: "Ventas brutas anuales (Monto en USD)",
                align: "center",
                sortable: false,
                value: "ventas_usd",
            },
        ],
        items: [
            {
                clasificacion: "Micro empresa",
                laboral: "Hasta 10",
                ventas: "Hasta 482",
                ventas_usd: "Hasta 146,609.94",
            },
            {
                clasificacion: "Pequeña empresa",
                laboral: "Hasta 50",
                ventas: "Hasta 4,817",
                ventas_usd: "De 146,609.95 a 1,465,186.89",
            },
            {
                clasificacion: "Mediana empresa",
                laboral: "Hasta 100",
                ventas: "Hasta 31,746",
                ventas_usd: "De 1,465,186.90 a 7,000,000.00",
            },
            {
                clasificacion: "Gran empresa",
                laboral: "Mayor a 100",
                ventas: "Hasta 31,746",
                ventas_usd: "Mayor a 7,000,000.00",
            },
        ],
    }),
};
</script>