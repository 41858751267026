<template>
  <v-card height="600" style="overflow-y: scroll" class="obsarvaciones-card mb-10">
    <v-card-title
      ><v-icon color="yellow darken-2" class="mr-5">mdi-message-alert-outline</v-icon>
      <div class="my-4 text-subtitle-1">Observaciones</div>
    </v-card-title>
    <v-card-text>
      <div v-for="observacion in observaciones" v-bind:key="observacion.id">
        <!-- Mostrar el texto enriquecido -->

        <div
          v-if="observacion.texto_observacion != '<p></p>'"
          v-html="observacion.texto_observacion"
        ></div>
        <!-- Dibujar la fecha almacenada en created_at formateada -->
        <div
          class="text-caption mb-5"
          v-if="observacion.texto_observacion != '<p></p>'"
        >
          {{ moment(observacion.created_at).format("DD/MM/YYYY") }}
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState } from "vuex";
export default {
  name: "ObservacionesComponent",
  computed: {
    ...mapState("registroProveedor", ["observaciones"]),
  },
};
</script>
<style lang="scss" scoped>
.obsarvaciones-card::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: #f5f5f5;
}

.obsarvaciones-card::-webkit-scrollbar {
  width: 8px;
  background-color: #f5f5f5;
}

.obsarvaciones-card::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #111c4e;
}
</style>
