<template>
  <div>
    <br />
    <v-row justify="space-between">
      <v-col cols="12" md="6">
        <v-autocomplete
          outlined
          color="secondary"
          label="Tipo de contribuyente *"
          :items="tipo_contribuyentes"
          item-text="nombre"
          item-value="id"
          :error-messages="tipoContribuyenteError"
          v-model="id_tipo_contribuyente_input"
          @change="obtener_tipos_identificaciones($event)"
        ></v-autocomplete>
        <v-autocomplete
          outlined
          color="secondary"
          label="Documento de identificación según origen del proveedor *"
          :items="tipo_identificaciones"
          item-text="nombre"
          item-value="id"
          :disabled="tipo_identificaciones_disable"
          :error-messages="identificacionTributariaError"
          v-model="id_tipo_identificacion_input"
          @change="seleccionar_tipo_identificacion($event)"
        >
        </v-autocomplete>
        <v-row>
          <v-form action="" @submit.prevent="submit" style="width: 100%">
            <v-col cols="12" sm="12" md="12" lg="12">
              <v-text-field
                :disabled="formulario_uno.id_tipo_identificacion == null"
                :readonly="dui_verificado"
                outlined
                color="secondary"
                label="Número de documento *"
                :error-messages="numeroDocumentoError"
                v-model="numero_documento_input"
                :placeholder="mascara_documento"
                :maxlength="lng_doc"
                @blur="$v.formulario_uno.numero_documento.$touch()"
              >
              </v-text-field>
              <v-text-field
                v-if="
                  id_tipo_identificacion_input !== 1 &&
                  id_tipo_identificacion_input !== null
                "
                color="secondary"
                outlined
                label="Nombre completo del proveedor *"
                v-model="nombre_proveedor_input"
                :error-messages="nombreProveedorError"
                @input="$v.formulario_uno.nombre_proveedor.$touch()"
                @blur="$v.formulario_uno.nombre_proveedor.$touch()"
              />
            </v-col>
            <v-col
              v-if="id_tipo_identificacion_input === 1"
              cols="12"
              sm="6"
              md="6"
              lg="4"
            >
              <VueSimpleRecaptcha
                v-if="isDui(numero_documento_input) && mostrar"
                :sitekey="key"
                ref="recaptcha"
                @callback="callback"
              />
              <v-btn
                v-show="isDui(numero_documento_input) && formulario_uno.captcha"
                :disabled="dui_verificado"
                :loading="verificando_dui"
                block
                x-large
                color="success"
                type="submit"
              >
                {{ dui_verificado ? "Verificado" : "Verificar" }}
              </v-btn>
            </v-col>
          </v-form>
        </v-row>
      </v-col>
      <v-col cols="12" md="6" lg="5" v-if="dui_verificado">
        <v-text-field
          color="secondary"
          label="Nombre del proveedor"
          :value="nombre_proveedor_input"
          v-model="nombre_proveedor_input"
          readonly
          dense
          class="no-border"
        />
        <br />
        <v-text-field
          color="secondary"
          label="Identificación tributaria según origen del proveedor"
          :value="formulario_uno.tipo_identificacion.nombre"
          readonly
          dense
          class="no-border"
        />
        <br />
        <v-text-field
          v-if="id_tipo_contribuyente_input === 1"
          color="secondary"
          :label="formulario_uno.tipo_identificacion.tipo_documento"
          :value="numero_documento_input"
          readonly
          hint="El número de DUI es válido"
          persistent-hint
          dense
          class="no-border green-hint"
        />
        <br v-if="id_tipo_contribuyente_input === 1" />
        <v-text-field
          color="secondary"
          label="Tipo de contribuyente"
          :value="formulario_uno.tipo_contribuyente"
          readonly
          dense
          class="no-border"
        />
      </v-col>
    </v-row>
    <br />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapActions, mapState } from "vuex";
import VueSimpleRecaptcha from "vue-simple-recaptcha";

const validarFormatoRegex = (formato) => {
  const validaciones = formato.split('|');
  const expresiones = validaciones.map((validacion) => {
    return new RegExp(`^${validacion.replace(/#/ig, '\\d')}$`);
  });

  return (value) => expresiones.some((expresion) => expresion.test(value));
};

export default {
  nombre: "StepOneComponent",
  data: () => ({
    key: process.env.VUE_APP_CAPTCHA,
    mostrar: true,
  }),
  validations() {
    return ({
      formulario_uno: {
        id_tipo_contribuyente: { required },
        id_tipo_identificacion: { required },
        numero_documento: { required, ...this.mascara_documento && ({ regex: validarFormatoRegex(this.mascara_documento) }), },
        nombre_proveedor: { required },
      },
    });
  },
  components: {
    VueSimpleRecaptcha,
  },
  computed: {
    ...mapState("registroProveedor", [
      "tipo_contribuyentes",
      "tipo_identificaciones_disable",
      "tipo_identificaciones",
      "mascara_documento",
      "lng_doc",
      "formulario_uno",
      "dui_verificado",
      "verificando_dui",
    ]),
    // Models para el formulario
    id_tipo_contribuyente_input: {
      get() {
        return this.formulario_uno.id_tipo_contribuyente;
      },
      set(value) {
        this.formulario_uno.id_tipo_contribuyente = value;
      },
    },
    id_tipo_identificacion_input: {
      get() {
        return this.formulario_uno.id_tipo_identificacion;
      },
      set(value) {
        this.formulario_uno.id_tipo_identificacion = value;
      },
    },
    numero_documento_input: {
      get() {
        return this.formulario_uno.numero_documento;
      },
      set(value) {
        this.formulario_uno.numero_documento = value;
      },
    },
    nombre_proveedor_input: {
      get() {
        return this.formulario_uno.nombre_proveedor;
      },
      set(value) {
        this.formulario_uno.nombre_proveedor = value;
      },
    },
    // Mensajes de validación
    tipoContribuyenteError() {
      const errors = [];
      if (!this.$v.formulario_uno.id_tipo_contribuyente.$dirty) return errors;
      !this.$v.formulario_uno.id_tipo_contribuyente.required &&
        errors.push("El tipo de contribuyente es requerido");
      return errors;
    },
    identificacionTributariaError() {
      const errors = [];
      if (!this.$v.formulario_uno.id_tipo_identificacion.$dirty) return errors;
      !this.$v.formulario_uno.id_tipo_identificacion.required &&
        errors.push("La identificación tributaria es requerida");
      return errors;
    },
    numeroDocumentoError() {
      const errors = [];
      if (!this.$v.formulario_uno.numero_documento.$dirty) return errors;

      !this.$v.formulario_uno.numero_documento.required && errors.push("El número de documento es requerido");

      if (this.mascara_documento && !this.$v.formulario_uno.numero_documento.regex) {
        errors.push(`El número de documento debe seguir el formato: ${this.mascara_documento.split('|').join(' o ')}`);
      }

      return errors;
    },
    nombreProveedorError() {
      const errors = [];
      if (!this.$v.formulario_uno.nombre_proveedor.$dirty) return errors;
      !this.$v.formulario_uno.nombre_proveedor.required &&
        errors.push("El nombre del proveedor es requerido");
      return errors;
    },
  },
  methods: {
    ...mapActions("registroProveedor", [
      "obtener_tipos_contribuyentes",
      "obtener_tipos_identificaciones",
      "seleccionar_tipo_identificacion",
      "verificar_dui",
    ]),
    verificar() {
      this.$v.$touch();
      return !this.$v.$invalid;
    },
    async submit() {
      // this.$refs.recaptcha.execute()
      if (this.formulario_uno.captcha) {
        await this.verificar_dui();
        this.token = null;
      } else {
        this.temporalAlert({
          show: true,
          message: "Debe verificar el captcha",
          type: "error",
        });
      }
    },
    async callback(token) {
      this.formulario_uno.captcha = token;
      this.mostrar = false;
    },
  },
};
</script>

<style lang="scss">
.no-border .v-input__control .v-input__slot::before,
.no-border .v-input__control .v-input__slot::after {
  border-color: transparent !important;
  transform: scale(0) !important;
}

.green-hint .v-messages__message {
  color: #57cc99;
}
</style>
