<template>
    <v-dialog v-model="dialog" width="1000">
        <template v-slot:activator="{ on, attrs }">
            <v-btn text color="secondary" dark v-bind="attrs" v-on="on">
                Lea aquí los términos y condiciones <v-icon>mdi-magnify-plus-outline</v-icon>
            </v-btn>
        </template>

        <v-card>
            <v-card-title>
                <v-row>
                    <v-col cols="12">
                        <v-btn icon color="secondary" @click="dialog = false">
                            <v-icon>mdi-close</v-icon>
                        </v-btn>
                    </v-col>
                    <v-col cols="12">
                        <p class="text-center">
                            T&Eacute;RMINOS Y CONDICIONES PARA USO DEL REGISTRO &Uacute;NICO DE PROVEEDORES DEL ESTADO Y COMPRASAL.
                        </p>
                    </v-col>
                </v-row>
            </v-card-title>

            <v-card-text>
<p ><span >El uso del Registro &Uacute;nico de Proveedores del Estado en el Sistema COMPRASAL, se realizar&aacute; bajo los siguientes t&eacute;rminos y condiciones, los cuales ser&aacute;n de obligatorio cumplimiento para los usuarios del sistema y determinan la vinculaci&oacute;n, obligaciones y responsabilidades de los intervinientes:</span></p>

<strong><span style="margin-top: 10px; display: block;">I. Registro &Uacute;nico de Proveedores del Estado en el sistema COMPRASAL</span></strong>
<p ><span style=''>En cumplimiento a La Ley de Compras P&uacute;blicas, este registro tiene por finalidad permitir a todas las personas naturales y jur&iacute;dicas, nacionales y extranjeras, acreditarse y formar parte de la base de datos de proveedores, como consecuencia de este registro se les permite a las personas mencionadas, participar en los procesos de contrataci&oacute;n que realizan las instituciones de la Administraci&oacute;n P&uacute;blica, siempre que cumplan con los requisitos de capacidad legal y no se encuentren impedidos, inhabilitados, pendientes del pago de multas o cualquier otra sanci&oacute;n.&nbsp;</span></p>
<p ><span style=''>El uso de este registro estar&aacute; sujeto a lo previsto en la Ley de Compras P&uacute;blicas, su Reglamento, normas complementarias, lineamientos emitidos por la Direcci&oacute;n Nacional de Compras, as&iacute; como lo consignado en estos t&eacute;rminos y condiciones.</span></p>
<p ><span style=''>El medio oficial de publicaci&oacute;n de las oportunidades de negocio o procesos de contrataci&oacute;n es el Sistema Electr&oacute;nico de Compras P&uacute;blicas -COMPRASAL-, al cual se puede acceder a trav&eacute;s del siguiente sitio web: www.comprasal.gob.sv.</span></p>

<strong><span style="margin-top: 10px; display: block;">II. Manejo de Cuentas.</span></strong>
<ol >
    <li><span >El uso de la cuenta en el Sistema Comprasal es personal y no se permite que sea cedida, prestada o utilizada por persona distinta a la registrada como proveedor o por sus designados debidamente, por tanto, la persona registrada es responsable por las acciones efectuadas con su usuario.&nbsp;</span></li>
    <li><span style=''>Los proveedores deber&aacute;n actualizar su informaci&oacute;n constantemente, en especial lo relativo a su clasificaci&oacute;n, representante legal, direcci&oacute;n, n&uacute;meros de contacto, accionistas, porcentajes o n&uacute;mero de acciones y estar&aacute;n en la obligaci&oacute;n de actualizar los datos.&nbsp;</span></li>
    <li><span style=''>La veracidad de la informaci&oacute;n y de los documentos compartidos mediante el sistema, son responsabilidad del usuario acreditado que la registre, por tanto, si un usuario sospecha del uso indebido de su cuenta, debe inmediatamente cambiar la contrase&ntilde;a de acceso e informarlo a la Direcci&oacute;n Nacional de Compras a trav&eacute;s de los mecanismos establecidos</span></li>
    <li><span style=''>La DINAC podr&aacute; cancelar, previo aviso, los usuarios que durante 30 d&iacute;as calendario, no hayan completado todos los requerimientos de perfil exigidos en COMPRASAL.&nbsp;</span></li>
    <li><span style=''>La DINAC tendr&aacute; la facultad de suspender y/o eliminar unilateral y autom&aacute;ticamente a los usuarios que incumplan las condiciones de uso de COMPRASAL.</span></li>
</ol>

<strong><span style="margin-top: 10px; display: block;">III. Red de Comunicaci&oacute;n:</span></strong>
<ol >
    <li><span >La DINAC no se hace responsable por errores, omisiones, falsedades o cualquier otra anomal&iacute;a de la informaci&oacute;n ingresada a COMPRASAL. &nbsp;</span></li>
    <li><span >La calidad y condiciones de funcionamiento del servicio de acceso a internet, es responsabilidad de cada usuario, por lo que deben velar porque se encuentre en condiciones adecuadas &oacute;ptimas condiciones al momento de hacer uso del Sistema Electr&oacute;nico de Compras P&uacute;blicas, lo cual debe ser verificado por este y por su proveedor de servicios de internet.&nbsp;</span></li>
    <li><span >Las fallas en el servicio de red seleccionado por los usuarios y las consecuencias derivadas de dicha circunstancia, no constituir&aacute; justificaci&oacute;n que libere al usuario de la responsabilidad que esto podr&iacute;a implicar de conformidad a la ley.</span></li>
</ol>

<strong><span style="margin-top: 10px; display: block;">IV. Prestaci&oacute;n de Servicios:&nbsp;</span></strong>
<p ><span>De acuerdo con los permisos para cada usuario, se tendr&aacute; acceso a los servicios siguientes:&nbsp;</span></p>
<ol >
    <li><span style=''>Registro &Uacute;nico de Proveedores del Estado (RUPES).</span></li>
</ol>
<ol >
    <li><span style=''>Registro para participar en los m&eacute;todos de contrataci&oacute;n previstos en la Ley de Compras P&uacute;blicas.</span></li>
    <li><span style=''>Registro de Sanciones.</span></li>
    <li><span style=''>Otros que la DINAC incorpore a futuro.</span></li>
</ol>

<p ><span>La DINAC establecer&aacute; los servicios que ser&aacute;n habilitados para cada usuario y har&aacute; las verificaciones que considere pertinentes antes de su habilitaci&oacute;n.</span></p>
<strong><span style="margin-top: 10px; display: block;">V. Responsabilidades.</span></strong>
<p ><span>La DINAC no es responsable de:</span></p>
<ol >
    <li><span style=''>Los resultados de los procesos de contrataci&oacute;n que lleven a cabo las instituciones del Estado.</span></li>
    <li><span style=''>De cualquier inexactitud, error u omisi&oacute;n de la informaci&oacute;n difundida a trav&eacute;s de COMPRASAL.</span></li>
    <li><span style=''>Los virus que puedan contener los documentos que adjuntan los proveedores.</span></li>
    <li><span style=''>Cualquier actuaci&oacute;n que sea de exclusiva responsabilidad de los usuarios del sistema COMPRASAL.</span></li>
</ol>

<strong><span style="margin-top: 10px; display: block;">VI. Reglas de Uso Especiales para Proveedores.</span></strong>
<p ><strong><span>A.&nbsp;Declaraci&oacute;n.</span></strong></p>
<p ><span>Los Proveedores del Estado declaran conocer y aceptar que:</span></p>
<ol >
    <li><span style=''>La DINAC podr&aacute; en cualquier momento modificar todo o parte de las presentes condiciones de uso.</span></li>
    <li><span style=''>Las normas y pol&iacute;ticas de uso del Registro &Uacute;nico de Proveedores del Estado (RUPES).</span></li>
    <li><span style=''>Que la informaci&oacute;n relativa a sus datos generales y documentaci&oacute;n legal registrada en COMPRASAL, estar&aacute; disponible para toda la Administraci&oacute;n del Estado, la que podr&aacute; efectuar consultas sobre la misma.&nbsp;</span></li>
    <li><span style=''>La obligaci&oacute;n de actualizar a la brevedad posible, toda la informaci&oacute;n pertinente que se refiera al Registro &Uacute;nico de Proveedores del Estado, entre ellas, la informaci&oacute;n general, &nbsp;documentaci&oacute;n legal pertinente e informar a las entidades sobre sanciones o multas pendientes de pago.</span></li>
</ol>

<p ><strong><span style="margin-top: 10px; display: block;">B.&nbsp;Autorizaciones.</span></strong></p>
<ol >
    <li><span style=''>Autoriza a la DINAC, para que, dentro del marco legal, consulte los datos registrados, en las distintas fuentes oficiales de informaci&oacute;n, tales como Centro Nacional de Registros, Instituto Salvadore&ntilde;o del Seguro Social, Registro Nacional de las Personas Naturales, Comisi&oacute;n Nacional de la Micro y Peque&ntilde;a Empresa, entre otros. Esta autorizaci&oacute;n se entender&aacute; otorgada para que los administradores de dichas fuentes puedan entregar la informaci&oacute;n requerida por la DINAC, sin ninguna restricci&oacute;n legal, a fin de que las Unidades de Compras P&uacute;blicas y otros intervinientes del ciclo de compra puedan consultar la misma a fin de cumplir con los principios de la Ley de Compras P&uacute;blicas.&nbsp;</span></li>
    <li><span style=''>Autoriza a la DINAC como administrador del Sistema COMPRASAL para incorporar y usar sus datos en futuros registros.</span></li>
</ol>

<p ><strong><span style="margin-top: 10px; display: block;">C. Inscripci&oacute;n.&nbsp;</span></strong></p>
<ol >
    <li><span style=''>Para inscribirse en el Registro &Uacute;nico de Proveedores del Estado de COMPRASAL, el proveedor debe cumplir con los pasos y requerimientos establecidos por la DINAC.</span></li>
    <li><span style=''>El usuario autoriza que, a partir de la fecha del registro, el correo electr&oacute;nico proporcionado ser&aacute; manejado para el env&iacute;o de avisos, comunicados y notificaciones.</span></li>
</ol>

<p ><strong><span style="margin-top: 10px; display: block;">D.&nbsp;Sobre la Informaci&oacute;n del Registro &Uacute;nico de Proveedores del Estado.</span></strong></p>
<div >
    <ol style="margin-bottom:0cm;list-style-type: decimal;margin-left:0cmundefined;">
        <li ><span style=''>Los Proveedores del Estado aceptan y autorizan, que toda la informaci&oacute;n contenida en el Registro &Uacute;nico de Proveedores del Estado de COMPRASAL, sea utilizada en los procesos de tratamiento de datos por parte de la DINAC y las instituciones p&uacute;blicas del Estado de conformidad con la Ley de Acceso a la Informaci&oacute;n P&uacute;blica y su reglamento.</span></li>
    </ol>
</div>

<p ><strong><span style="margin-top: 10px; display: block;">E.&nbsp;Responsabilidad de los proveedores por la informaci&oacute;n.</span></strong></p>
<ol >
    <li><span style=''>La veracidad y oportunidad de la informaci&oacute;n registrada ser&aacute; responsabilidad del propio proveedor y de los usuarios pertinentes.</span></li>
    <li><span style=''>El proveedor tiene la obligaci&oacute;n de actualizar permanentemente los datos requeridos y necesarios para los procesos de compra se realicen de conformidad con la normativa vinculante, entre estas, la Ley de Compras P&uacute;blicas; dicha obligaci&oacute;n la ostentar&aacute; mientras permanezca en el Registro &Uacute;nico de Proveedores del Estado o si tiene intenciones de contratar con el Estado y actualizar&aacute; cualquier circunstancia que pueda influir en su correcta individualizaci&oacute;n.&nbsp;</span></li>
</ol>

<p ><strong><span style="margin-top: 10px; display: block;">F.&nbsp;Suspensi&oacute;n del Registro &Uacute;nico de Proveedores del Estado en el Sistema COMPRASAL.</span></strong></p>
<ol class="decimal_type" >
    <li><span style=''>Las Unidades de Compras P&uacute;blicas deber&aacute;n informar inmediatamente a trav&eacute;s del sistema COMPRASAL cualquier sanci&oacute;n que se aplique a los proveedores que se encuentren en el RUPES, en caso de no encontrarse en el RUPES deber&aacute;n informarlo directamente a la DINAC por los canales de comunicaci&oacute;n oficiales.</span></li>
    <li><span style=''>La DINAC, podr&aacute; suspender o inhabilitar el usuario de los proveedores inscritos en el Registro &Uacute;nico de Proveedores del Estado en el Sistema COMPRASAL, si concurre alguna de las siguientes circunstancias:&nbsp;</span>
        <ol class="decimal_type" style="list-style-type: undefined;">
            <li><span style=''>Si el proveedor se encuentra pendiente del pago de multas o inhabilitado, posterior a su registro.</span></li>
        </ol>
    </li>
    <li><span style=''>Las Unidades de Compras P&uacute;blicas no pueden invitar a participar, contratar o adjudicar un proceso, al proveedor pendiente de pago de multas o inhabilitado&nbsp;</span></li>
</ol>

<strong><span style="margin-top: 10px; display: block;">VII. Contacto para consultas.&nbsp;</span></strong>
<p ><span>a. Las consultas a este servicio de soporte se podr&aacute;n ingresar por v&iacute;a telef&oacute;nica a trav&eacute;s del tel&eacute;fono (503) <strong>2244-3171</strong>, y el correo electr&oacute;nico&nbsp;</span><a href="mailto:dinac.solicitudes@gob.sv"><span>dinac.solicitudes@gob.sv</span></a><span>.</span></p>
<p ><strong>b. Los d&iacute;as y horarios de atenci&oacute;n, son de lunes a viernes (excepto festivos) en los horarios: 8:00am a 4:00pm, San Salvador, El Salvador.</strong></p>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="secondary" @click="dialog = false">
                    Cerrar
                </v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<style lang="scss" scoped>
:deep(.v-btn__content) {
    text-transform: none !important;
}
</style>

<script>
export default {
    name: "ModalTerminosComponent",
    data: () => ({
        dialog: false,
    }),
};
</script>