<template>
  <v-app class="bgMinsal">
    <app-loader v-if="loader"></app-loader>
    <v-main>
      <v-container :fluid="solicitud_con_observaciones">
        <v-layout :class="solicitud_con_observaciones ? 'mx-16' : ''">
          <v-flex>
            <v-img
              class="mx-auto"
              src="@/assets/img/logo_512_negro.png"
              max-width="75"
            />
            <p class="text-left text-h6 bold secondary--text my-6 mx-6">
              Registro de proveedor nacional y extranjero
            </p>
            <v-stepper
              v-model="paso_actual_model"
              color="secondary"
              class="elevation-0"
            >
              <v-stepper-header>
                <v-stepper-step step="1">
                  Identificación tributaria
                </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="2"> Datos generales </v-stepper-step>
                <v-divider></v-divider>
                <v-stepper-step step="3">
                  Configuración de usuario
                </v-stepper-step>
              </v-stepper-header>
              <v-stepper-items>
                <v-row>
                  <v-col
                    cols="12"
                    sm="12"
                    :xl="solicitud_con_observaciones ? '7' : '12'"
                  >
                    <v-stepper-content step="1">
                      <step-one ref="StepOne" />
                      <v-btn
                        color="secondary"
                        outlined
                        :to="{ name: 'dashboard' }"
                      >
                        Cancelar
                      </v-btn>
                      <v-btn
                        color="secondary"
                        class="white--text ml-4"
                        @click="continuar_paso_uno('StepOne')"
                      >
                        Continuar
                      </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="2">
                      <step-two ref="StepTwo" />
                      <v-btn color="secondary" outlined @click="pasoPrevio">
                        Atrás
                      </v-btn>
                      <v-btn
                        :loading="loading_paso_dos"
                        color="secondary"
                        class="white--text ml-4"
                        @click="continuar_paso_dos('StepTwo')"
                      >
                        Continuar
                      </v-btn>
                    </v-stepper-content>
                    <v-stepper-content step="3">
                      <StepThree ref="StepThree" />
                      <v-btn color="secondary" outlined @click="pasoPrevio">
                        Atrás
                      </v-btn>
                      <v-btn
                        :loading="loading_guardar_registro"
                        color="secondary"
                        class="white--text ml-4"
                        @click="confirmarEnvio()"
                      >
                        {{ solicitud_con_observaciones ? "Guardar" : "Registrarme" }}
                      </v-btn>
                    </v-stepper-content>
                  </v-col>
                  <v-col
                    v-if="solicitud_con_observaciones"
                    cols="12"
                    sm="12"
                    lg="5"
                  >
                    <ObservacionesComponentVue />
                  </v-col>
                </v-row>
              </v-stepper-items>
            </v-stepper>
          </v-flex>
        </v-layout>
      </v-container>
    </v-main>

    <modal-confirmar
      :show="dialogoConfirmacion"
      btnConfirmar="Confirmar"
      title="¿Desea enviar los datos de su solicitud?"
      message="De confirmarse la siguiente acción, los cambios no serán reversibles."
      @close="dialogoConfirmacion = false"
      @confirm="continuar_paso_tres('StepThree')"
    />
  </v-app>
</template>

<script>
import StepOne from "../RegistroProveedores/components/StepOneComponent.vue";
import StepTwo from "../RegistroProveedores/components/StepTwoComponent.vue";
import StepThree from "../RegistroProveedores/components/StepThreeComponent.vue";
import ObservacionesComponentVue from "../RegistroProveedores/components/ObservacionesComponent.vue";
import modalConfirmar from "./../../components/ConfirmationDialogComponent.vue";
import { mapState, mapActions } from "vuex";
import AppLoader from "../../components/AppLoaderComponent.vue";

export default {
  name: "registerView",
  data: () => ({
    stepper: 1,
    formulario: {},
    dialogoConfirmacion: false,
  }),
  components: {
    StepOne,
    StepTwo,
    StepThree,
    ObservacionesComponentVue,
    modalConfirmar,
    AppLoader,
  },
  computed: {
    ...mapState("registroProveedor", [
      "paso_actual",
      "loading_paso_dos",
      "datos",
      "TercerDatos",
      "segundosDatos",
      "verified",
      "verified2",
      "id_proveedor",
      "solicitud_con_observaciones",
      "loading_guardar_registro",
    ]),
    ...mapState("utils", ["loader", "menu"]),
    paso_actual_model: {
      get() {
        return this.paso_actual;
      },
      set(value) {
        this.paso_actual = value;
      },
    },
  },
  methods: {
    ...mapActions("registroProveedor", [
      "siguientePaso",
      "pasoPrevio",
      "obtener_paises",
      "guardar_informacion_inicial",
      "definir_estado_inicial",
      "verificar_proceso",

      "guardarProveedor",
      "SegundoPaso",
      "CompletarRegistro",
      "obtener_tipos_contribuyentes",
    ]),
    prevStep() {
      this.stepper--;
    },
    continuar_paso_uno(ref) {
      const data = this.$refs[ref].verificar();
      if (!data) return;
      this.obtener_paises();
      this.siguientePaso();
    },
    async continuar_paso_dos(ref) {
      const data = this.$refs[ref].verificar();
      if (!data) return;
      await this.guardar_informacion_inicial();
    },
    async confirmarEnvio() {
      // Verificamos las validaciones
      this.$refs.StepThree.$v.$touch();
      if (this.$refs.StepThree.$v.$invalid) return
      this.dialogoConfirmacion = true;
    },
    async continuar_paso_tres(ref) {
      this.$refs[ref].guardarRegistro();
    },
    async guardarycontinuar(ref) {
      const response = await this.CompletarRegistro(this.TercerDatos);
      if (response.status === 200 || response.status === 201) {
        this.temporalAlert({
          show: true,
          message: "Datos registrados, pendiente de aprobación",
          type: "success",
        });
        this.$router.push("/identidad-digital");
      }
    },
  },
  async created() {
    this.definir_estado_inicial();

    await this.obtener_tipos_contribuyentes();
    // Obtenemos el token enviado en la url y lo imprimimos en consola
    const urlParams = new URLSearchParams(window.location.search);
    const token = urlParams.get("token");

    if (token) {
      const response = await this.verificar_proceso({
        ruta: this.$route.name,
        token,
      });

      if (response.status === 401) {
        this.temporalAlert({
          show: true,
          message: response.message,
          type: "warning",
        });
      }

      if (response.status != 200) {
        this.$router.push("/identidad-digital");
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.bold {
  font-weight: 500;
}

.v-stepper__header {
  box-shadow: none;
}

.theme--light.v-stepper {
  background: transparent;
}
</style>

<style>
.v-stepper__step.v-stepper__step--active span {
  background-color: #111c4e !important;
  border-color: #111c4e !important;
}
</style>
