<template>
  <div>
    <br v-if="!solicitud_con_observaciones" />
    <v-row v-if="!solicitud_con_observaciones">
      <v-col cols="12" md="4">
        <v-text-field
          :readonly="enviar_codigo_disable || tiempoEsperaCorreo > 0"
          :disabled="!disableAtVerify && correo_valido"
          outlined
          label="Correo electrónico *"
          color="secondary"
          v-model="correo_electronico_input"
          :error-messages="emailError"
        ></v-text-field>
      </v-col>
      <v-col cols="12" md="4">
        <v-btn
          v-show="!disableAtVerify && !correo_valido"
          :disabled="enviar_codigo_disable || tiempoEsperaCorreo > 0"
          :loading="enviar_codigo_disable"
          color="secondary"
          class="white--text"
          block
          x-large
          @click="enviar_codigo_verificacion"
        >
          <span v-if="tiempoEsperaCorreo > 0">
            Reenviar en {{ tiempoEsperaCorreo }} segundos
          </span>
          <span v-else> Enviar código de verificación </span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row v-if="!solicitud_con_observaciones">
      <v-col cols="12" md="4">
        <v-text-field
          v-mask="'######'"
          outlined
          label="Confirmar código de verificación *"
          color="secondary"
          v-model="codigo_verificacion_input"
          :error-messages="codeError"
          :loading="loading_verificar_correo"
          @change="verificar_codigo_correo"
          :disabled="correo_valido"
        >
          <template v-slot:append>
            <v-icon v-if="correo_valido" color="green" class="mr-2" size="20">
              mdi-check-circle
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <modal-terminos />
        <v-checkbox
          color="secondary"
          large
          label="Acepto los términos y condiciones"
          v-model="terminos_input"
          :error-messages="termsError"
        ></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" md="6">
        <v-btn text color="secondary" dark @click="imprimirDeclaracion()">
          <span style="text-transform: none !important"
            >Descargue aquí la declaración jurada y luego suba el archivo
            firmado
          </span>
          <v-icon>mdi-download</v-icon>
        </v-btn>
        <v-file-input
          persistent-hint
          hint="Archivo PDF tamaño máximo 250MB"
          outlined
          label="Declaración jurada *"
          truncate-length="15"
          accept=".pdf"
          v-model="archivo_declaracion_input"
          :error-messages="declarationFileError"
          messages="Utilice un archivo PDF con tamaño no mayor a 250MB"
        >
          <template
            v-if="formulario_tres.archivo_declaracion != null && token != ''"
            v-slot:append
          >
            <v-icon @click="descargar_archivo" color="green"
              >mdi-file-download</v-icon
            >
          </template>
        </v-file-input>
        <v-checkbox
          color="secondary"
          large
          label="Acepto la declaración jurada"
          v-model="declaracion_input"
          :error-messages="declarationError"
        />
      </v-col>
    </v-row>
    <br />
  </div>
</template>

<script>
import { required, email, helpers } from "vuelidate/lib/validators";
import LegacyValidations from "@/utils/legacy-validations";
import modalTerminos from "./ModalTerminosComponent.vue";
import { mapActions, mapState } from "vuex";

const file_type_validation = (value) => {
  if (!value) {
    return true;
  }
  let extension = /(\.pdf)$/i;
  let file = value;
  return extension.test(file.name);
};

export default {
  name: "StepThreeComponent",
  components: {
    modalTerminos,
  },
  data: () => ({
    verificandoCodigo: false,
    emailValido: false,
    disableAtVerify: false,
  }),
  validations: {
    formulario_tres: {
      correo_electronico: { required, email },
      codigo_verificacion: { required },
      terminos: { required },
      declaracion: { required },
      archivo_declaracion: {
        required,
        file_type_validation,
        fileMaxSize: LegacyValidations.maxFileSizeRule(),
      },
    },
  },
  computed: {
    ...mapState("registroProveedor", [
      "segundosDatos",
      "tiempoEsperaCorreo",
      "id_proveedor",
      "formulario_tres",
      "loading_verificar_correo",
      "correo_valido",
      "enviar_codigo_disable",
      "solicitud_con_observaciones",
      "token",
    ]),
    // Models para el formulario
    correo_electronico_input: {
      get() {
        return this.formulario_tres.correo_electronico;
      },
      set(value) {
        this.formulario_tres.correo_electronico = value;
      },
    },
    codigo_verificacion_input: {
      get() {
        return this.formulario_tres.codigo_verificacion;
      },
      set(value) {
        this.formulario_tres.codigo_verificacion = value;
      },
    },
    terminos_input: {
      get() {
        return this.formulario_tres.terminos;
      },
      set(value) {
        if (value) {
          this.formulario_tres.terminos = value;
        } else {
          this.formulario_tres.terminos = null;
        }
      },
    },
    declaracion_input: {
      get() {
        return this.formulario_tres.declaracion;
      },
      set(value) {
        if (value) {
          this.formulario_tres.declaracion = value;
        } else {
          this.formulario_tres.declaracion = null;
        }
      },
    },
    archivo_declaracion_input: {
      get() {
        return this.formulario_tres.archivo_declaracion;
      },
      set(value) {
        this.formulario_tres.archivo_declaracion = value;
      },
    },
    emailError() {
      const errors = [];
      if (!this.$v.formulario_tres.correo_electronico.$dirty) return errors;
      !this.$v.formulario_tres.correo_electronico.required &&
        errors.push("El correo electrónico es requerido");
      !this.$v.formulario_tres.correo_electronico.email &&
        errors.push("El correo electrónico no es válido");
      return errors;
    },
    codeError() {
      const errors = [];
      if (!this.$v.formulario_tres.codigo_verificacion.$dirty) return errors;
      !this.$v.formulario_tres.codigo_verificacion.required &&
        errors.push("El código de verificación es requerido");
      return errors;
    },
    termsError() {
      const errors = [];
      if (!this.$v.formulario_tres.terminos.$dirty) return errors;
      !this.$v.formulario_tres.terminos.required &&
        errors.push("Debe aceptar los términos y condiciones");
      return errors;
    },
    declarationError() {
      const errors = [];
      if (!this.$v.formulario_tres.declaracion.$dirty) return errors;
      !this.$v.formulario_tres.declaracion.required &&
        errors.push("Debe aceptar la declaración jurada");
      return errors;
    },
    declarationFileError() {
      const errors = [];
      if (!this.$v.formulario_tres.archivo_declaracion.$dirty) return errors;
      !this.$v.formulario_tres.archivo_declaracion.required &&
        errors.push("Debe subir la declaración jurada");
      !this.$v.formulario_tres.archivo_declaracion.file_type_validation &&
        errors.push("El archivo debe ser un PDF");
      !this.$v.formulario_tres.archivo_declaracion.fileMaxSize && LegacyValidations.pushToErrorsArray(errors);
      return errors;
    },
  },
  methods: {
    ...mapActions("registroProveedor", [
      "ultimoPaso",
      "obtenerDeclaracionJurada",
      "enviar_codigo",
      "verificar_codigo",
      "completar_registro",
      "definir_estado_inicial",
      "descargar_archivo",
    ]),
    verificar() {
      this.$v.$touch();
      if (this.$v.$invalid) return;
      return this.formulario;
    },
    async verificar_codigo_correo() {
      const response = await this.verificar_codigo();
      if (response != null && response.status === 200) {
        this.temporalAlert({
          show: true,
          message: response.data.message || response.data.description,
          type: "success",
        });
      }
    },
    async imprimirDeclaracion() {
      const response = await this.obtenerDeclaracionJurada();
      if (!response) {
        this.temporalAlert({
          show: true,
          message: "Ocurrió un error al descargar la plantilla",
          type: "error",
        });
      } else {
        const fileUrl = window.URL.createObjectURL(new Blob([response]));
        const fileLink = document.createElement("a");

        fileLink.href = fileUrl;
        fileLink.setAttribute("download", "plantilla.pdf");
        document.body.appendChild(fileLink);
        fileLink.click();
      }
    },
    async enviar_codigo_verificacion() {
      this.$v.formulario_tres.correo_electronico.$touch();
      if (!this.$v.formulario_tres.correo_electronico.$invalid) {
        if (this.isEmail(this.formulario_tres.correo_electronico)) {
          const response = await this.enviar_codigo();
          if (response.status === 200) {
            this.temporalAlert({
              show: true,
              message: response.data.message || response.data.description,
              type: "success",
            });
          }
        }
      }
    },
    getFileSize(f = null) {
      if (f !== null) {
        const hasValidSize = LegacyValidations.maxFileSizeRule()(f);
        if (!hasValidSize){
          this.temporalAlert({
            show: true,
            message: "El tamaño del archivo no debe ser mayor a 250MB",
            type: "error",
          });
        }
      } else {
        this.formulario_tres.declaracion = null;
      }
    },
    async guardarRegistro() {
      this.$v.$touch();
      if (!this.$v.$invalid) {
        const response = await this.completar_registro();
        if (response.status === 200 || response.status === 201) {
          this.temporalAlert({
            show: true,
            message: "Datos registrados, pendiente de aprobación",
            type: "success",
          });
          this.$router.push("/identidad-digital");
        }
      } else {
        this.formulario_tres.declaracion = null;
      }
    },
  },
};
</script>
